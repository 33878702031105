import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { between, Device, from, until } from '@helpers/media';
import SiteWideStyles from '@stories/Components/Global/SiteWide/SiteWide.styles';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import { m } from 'framer-motion';
import styled from 'styled-components';
const Container = styled(m.section) `
  position: relative;
  display: grid;
  height: 100%;
  min-height: 296px;

  @media ${from(Device.DesktopSmall)} {
    height: 90vh;
    max-height: min(100vh - 185px, 786px);
  }
`;
const ImageWrapper = styled.div `
  position: relative;
  grid-area: 1 / 1;
`;
const ContentWrapper = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  grid-area: 1 / 1;
  height: 100%;
  justify-content: flex-start;

  ${SiteWideStyles.Container} {
    display: flex;
    align-items: center;
    height: 100%;

    @media ${between(Device.TabletLarge, Device.DesktopSmall)} {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @media ${until(Device.TabletLarge)} {
      padding-top: 75px;
      padding-bottom: 75px;
    }
  }
`;
const Overlay = styled.div `
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.43);
  height: 100%;
  width: 100%;
  z-index: 1;
`;
const ContentWrapperInner = styled.div `
  position: relative;
  z-index: 2;

  @media ${from(Device.TabletLarge)} {
    padding-left: max(10vw, 64px);
  }
`;
const Title = styled(m.h1) `
  display: block;
  color: ${brand.white};
  ${fonts.DaxPro.Bold};
  font-size: clamp(30px, 20px + 2.5vw, 50px);
  line-height: 1.2;
  max-width: clamp(350px, 70vw, 526px);
  margin-bottom: 20px;
`;
const Description = styled(m.p) `
  ${ParagraphStyles.RegularStyles};
  color: ${brand.white};
  max-width: clamp(350px, 70vw, 800px);
`;
const CtaWrapper = styled.div `
  margin-top: 25px;
`;
export const HomepageCarouselItemStyles = {
    Container,
    ContentWrapper,
    ContentWrapperInner,
    CtaWrapper,
    ImageWrapper,
    Overlay,
    Title,
    Description,
};
