import { Device, from } from '@helpers/media';
import styled, { css } from 'styled-components';
export const SiteWideDesktop = 1757;
export const SitePaddingMobile = 18;
export const SitePaddingDesktop = 24;
const Container = styled.div `
  --site-wide: 100%;
  --site-padding: ${SitePaddingMobile}px;

  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  padding-left: var(--site-padding);
  padding-right: var(--site-padding);
  width: 100%;

  @media ${from(Device.Desktop)} {
    --site-padding: ${SitePaddingDesktop}px;
    --site-wide: ${SiteWideDesktop}px;
    max-width: calc(var(--site-wide) + (var(--site-padding) * 2));
  }

  ${({ isThin }) => isThin &&
    css `
      @media ${from(Device.DesktopSmall)} {
        --site-wide: 863px;
        max-width: calc(var(--site-wide) + (var(--site-padding) * 2));
      }
    `}
`;
export default {
    Container,
};
