import { HydrateOption } from '@core/enums';
import { Device, until } from '@helpers/media';
import { isSSR } from '@helpers/ssr';
import withWidget from '@hoc/withWidget';
import useMedia from '@hooks/useMedia';
import SiteWide from '@stories/Components/Global/SiteWide/SiteWide';
import Breadcrumbs from '@stories/Widgets/Meta/Breadcrumbs/Breadcrumbs.widget';
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { HomepageCarouselStyles as S } from './HomepageCarousel.styles';
import HomepageCarouselItem from './HomepageCarouselItem/HomepageCarouselItem';
import { useAutoplay } from '@hooks/useAutoplay';
import { useInView } from '@hooks/useInView';
import { withMotionMax } from '@hoc/withMotionMax';
import DotPagination from '@stories/Components/Misc/DotPagination/DotPagination';
const HomepageCarousel = ({ items, breadcrumb, autoplay = 0, }) => {
    const isMobile = useMedia([until(Device.Tablet)], [true], false);
    const [refContainer, inView] = useInView({}, true);
    const { activeIndex, duration, paused, setActiveIndex, setPaused } = useAutoplay({
        enabled: autoplay > 0,
        duration: autoplay,
        items,
    });
    React.useEffect(() => {
        setPaused(!inView);
    }, [inView]);
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            setActiveIndex(Math.min(activeIndex + 1, items.length - 1));
        },
        onSwipedRight: () => {
            setActiveIndex(Math.max(activeIndex - 1, 0));
        },
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ...swipeHandlers, ref: (r) => {
                refContainer.current = r;
                swipeHandlers.ref(r);
            } },
            React.createElement(S.Container, null,
                breadcrumb && (React.createElement(S.BreadcrumbWrapper, null,
                    React.createElement(Breadcrumbs, { items: breadcrumb }))),
                React.createElement(S.ItemList, null,
                    !isMobile &&
                        !isSSR() &&
                        items.map((item, index) => (React.createElement(S.Item, { key: item.id, "aria-current": activeIndex === index },
                            React.createElement(HomepageCarouselItem, { ...item, active: activeIndex === index, index: index })))),
                    isMobile || (isSSR() && items.length > 0) ? (React.createElement(S.Item, { key: items[0].id, "aria-current": true },
                        React.createElement(HomepageCarouselItem, { ...items[0], active: true, index: 0 }))) : null),
                items.length > 1 && !isMobile && !isSSR() && (React.createElement(S.PaginationWrapper, null,
                    React.createElement(SiteWide, null,
                        React.createElement(DotPagination, { autoplay: duration, count: items.length, paused: paused, value: activeIndex, onChange: (index) => {
                                setActiveIndex(index);
                                setPaused(true);
                            }, onHover: (hover) => setPaused(hover) }))))))));
};
export default withWidget(withMotionMax(HomepageCarousel), 'HomepageCarousel', {
    hydrate: HydrateOption.Always,
});
