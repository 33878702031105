import { LinkButton } from '@stories/Components/Buttons/Button/Button';
import SiteWide from '@stories/Components/Global/SiteWide/SiteWide';
import Image from '@stories/Components/Media/Image/Image';
import { m } from 'framer-motion';
import React from 'react';
import { HomepageCarouselItemStyles as S } from './HomepageCarouselItem.styles';
const HomepageCarouselItem = ({ active, cta, image, index, title, description, }) => {
    return (React.createElement(S.Container, { initial: active ? 'visible' : 'hidden', animate: active ? 'visible' : 'hidden', variants: { hidden: { opacity: 0 }, visible: { opacity: 1 } } },
        React.createElement(S.ImageWrapper, null,
            React.createElement(Image, { ...image, alt: title || description || '', objectFit: "cover", loading: index === 0 ? 'eager' : 'lazy', preload: index === 0 })),
        React.createElement(S.Overlay, { style: { transformOrigin: 'top center' } }),
        React.createElement(S.ContentWrapper, null,
            React.createElement(SiteWide, null,
                React.createElement(S.ContentWrapperInner, null,
                    React.createElement(S.Title, { as: index === 0 ? m.h1 : m.h2 }, title),
                    description && React.createElement(S.Description, null, description),
                    cta && (React.createElement(S.CtaWrapper, null,
                        React.createElement(LinkButton, { branding: "white", href: cta.url, target: cta.target, title: cta.title }))))))));
};
export default HomepageCarouselItem;
