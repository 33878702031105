import * as React from 'react';
export function useAutoplay(params) {
    const refInterval = React.useRef();
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [paused, setPaused] = React.useState(params.duration === 0);
    const activeItem = params.items[activeIndex];
    const activeItemDuration = activeItem.duration ?? params.duration;
    React.useEffect(() => {
        if (params.enabled && !paused) {
            refInterval.current = window.setTimeout(update, activeItemDuration);
        }
        return cleanup;
        function update() {
            if (paused) {
                return;
            }
            let newIndex = activeIndex + 1;
            if (newIndex === params.items.length) {
                newIndex = 0;
            }
            setActiveIndex(newIndex);
        }
        function cleanup() {
            if (refInterval.current) {
                window.clearInterval(refInterval.current);
            }
        }
    }, [activeIndex, activeItemDuration, paused, params.enabled]);
    return {
        activeIndex,
        duration: activeItemDuration,
        setActiveIndex,
        paused,
        setPaused,
    };
}
